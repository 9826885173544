#homepage {
}

html {
  overflow: hidden;
}

.App {
  position: relative;
  height: 100vh;
  max-height: 100vh;

  --font: "Times New Roman", Times, serif;
  --font-mono: monospace;
  font-family: var(--font);
  -webkit-tap-highlight-color: transparent;

  &.theme-light {
    --color-text: black;
    --color-bg: rgb(203, 231, 230);
    --color-accent: rgb(58, 19, 131);
    --color-accent2: rgb(255, 51, 0);
    --color-accent-focus: var(--color-accent2);
    // --opacity-inactive: 0.5;
    --opacity-inactive: 0.666;
  }
  &.theme-dark {
    --color-text: rgb(148, 243, 164);
    --color-bg: rgb(7, 0, 14);
    --color-accent: rgb(171, 128, 251);
    --color-accent2: rgb(255, 115, 80);
    --color-accent-focus: var(--color-accent);
    // --opacity-inactive: 0.333;
    --opacity-inactive: 0.5;
  }

  background-color: var(--color-bg);
  color: var(--color-text);

  // width: 100%;

  .star,
  .active,
  .arrow {
    font-weight: normal;
    height: auto;
    border: 0;
    font-style: normal;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    // transition: transform 0.1s, font-size 0.1s;

    > div {
      transition: transform 0.1s;
      user-select: none;
    }
    &:hover > div {
      transform: scale(1.25);
    }
    &.active-bold:hover > div {
      font-weight: bolder;
    }
    &:active > div {
      transform: scale(0.8);
    }
  }

  .arrow,
  .edit {
    &:hover > div {
      transform: rotate(-45deg);
    }
    &:active > div {
      transform: rotate(-45deg) scale(0.8);
    }
  }

  .arrow,
  .star,
  .edit,
  img {
    user-select: none;
  }

  img {
    display: block;
  }

  button {
    user-select: none;

    cursor: pointer;
    background-color: var(--color-bg);
    border: 1px solid var(--color-text);
    border-top: 0;
    border-left: 0;

    color: var(--color-text);
    font: inherit;
    display: inline-flex;
    align-items: center;
    height: 30px;
    font-style: italic;
    img {
      margin-left: 5px;
      height: 20px;
    }

    &:disabled {
      opacity: var(--opacity-inactive);
    }
  }

  input[type="text"] {
    all: inherit;
    transition: none;
    border: 1px solid var(--color-text);
    border-top: 0;
    border-left: 0;
    max-width: 100%;
    width: 100%;
    display: inline;
    min-height: 0;
    &::placeholder {
      transition: none !important;
      color: inherit;
      opacity: var(--opacity-inactive);
      // text-decoration-color: gray;
    }
  }

  input[type="file"] {
    font-family: monospace;
    direction: rtl;
  }

  .text-button {
    height: auto;
    cursor: pointer;
    margin: 0;
    border: 0;
    padding: 0;
    color: var(--color-accent);
    &:hover {
      text-decoration: underline;
    }
  }

  textarea {
    padding: 5px;
    color: var(--color-text);
    margin-right: 10px;
    min-height: calc(5em + 10px);
    flex: 1;
    background-color: transparent;
    border-color: var(--color-accent);
    // font-family: inherit;
    font-size: inherit;
  }

  :not(.focus-color) {
    &:focus:not(:active) {
      transition: 0.1s;
      outline: none;
      // outline-offset: 5px;
      // outline-color: var(--color-accent2);
      // -webkit-tap-highlight-color: var(--color-accent2);
      // box-shadow: 0 0 0 2px var(--color-bg), 0 0 0 3px var(--color-accent);
      box-shadow: 0 0 0 2px var(--color-bg), 0 0 0 7px var(--color-accent-focus);
      z-index: 10;
      position: relative;
    }

    div:focus:not(:active) {
      box-shadow: 0 0 0 2px var(--color-bg), 0 0 0 7px var(--color-accent-focus);
    }
    textarea:focus:not(:active) {
      box-shadow: 0 0 0 2px var(--color-bg), 0 0 0 4px var(--color-accent-focus);
    }
  }
  .focus-color:focus {
    outline: none;
    color: var(--color-accent-focus) !important;
    box-shadow: 0;
    border-color: var(--color-accent-focus) !important;
    &::placeholder {
      color: var(--color-accent-focus) !important;
    }
  }
  *:active {
    outline: none;
    box-shadow: 0;
  }
  .image-link {
    display: block;
  }

  .date {
    font-family: monospace;
  }

  .url {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .url {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    font-family: var(--font-mono);
    min-width: 3em;

    color: var(--color-text);
    text-decoration: none;

    white-space: nowrap;
    display: inline-block;
    vertical-align: bottom;
    color: var(--color-accent2);

    &:visited {
      color: var(--color-accent);
    }
    &:hover {
      text-decoration: underline;
    }
  }
  .url-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    text-decoration: none;

    white-space: nowrap;
    vertical-align: bottom;
    // margin-bottom: 1px;
    margin: 2px;

    img {
      max-width: 1em;
      max-height: 1em;
      min-width: 0;
      min-height: 0;
    }
    // &:hover {
    //   // text-decoration: underline;
    //   border-bottom: 1px solid var(--color-accent);
    //   // padding-bottom: -1;
    //   margin-bottom: 0px;
    // }
    // &:visited {
    //   color: var(--color-accent);
    //   border-color: var(--color-accent2);
    // }
  }

  .username {
    color: var(--color-accent);
    font-style: italic;
  }

  .date {
    color: var(--color-accent2);
    font-family: var(--font-mono);
  }

  .app-footer,
  .app-header {
    transition: margin-right 0.5s;
    margin-right: var(--scrollbar-width);
  }

  .app-header {
    z-index: 2;
    top: 0;
    right: 0;

    position: absolute;
    padding: 10px;
    padding-right: 5px;

    .theme-toggle {
      user-select: none;

      span {
        border-radius: 50px;
        background-color: var(--color-bg);
        // border: 1px solid var(--color-text);
        line-height: 1em;
        padding: 5px;
        font-size: 14px;
      }
      cursor: pointer;
      // margin: 5px;
    }
  }

  .app-footer {
    // animation: fade-in 1s linear 0.25s;
    // animation-fill-mode: backwards;
    white-space: nowrap;

    transition: opacity 0.5s, margin-right 0.5s;
    opacity: 0;
    &.visible {
      opacity: 1;
    }

    z-index: 2;
    position: absolute;
    bottom: 0;
    right: 0;
    // left: 0;
    // width: 100%;
    display: flex;
    justify-content: flex-end;

    // padding: 5px;
    form {
      user-select: none;

      background-color: var(--color-bg);
      // border-radius: 15px;
      border-top-left-radius: 10px;
      // padding: 5px 10px;
      padding: 10px;
      padding-left: 15px;
    }
    button {
      // margin: 10px;
      margin-left: 5px;
    }
  }
}

#homepage {
  // position: absolute;
  // top: 0;
  // width: 100%;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;

  &.transition-down-enter {
    opacity: 0;
    transform: translateY(-100%);
  }
  &.transition-up-enter {
    opacity: 0;
    transform: translateY(-50%);
  }

  &.transition-down-enter-active,
  &.transition-up-enter-active {
    opacity: 1;
    transform: translateY(0%);
  }

  &.transition-down-exit,
  &.transition-up-exit {
    opacity: 1;
    transform: translateY(0%);
  }
  &.transition-down-exit-active {
    opacity: 0;
    transform: translateY(-100%);
  }
  &.transition-up-exit-active {
    opacity: 0;
    transform: translateY(-50%);
  }
}

.transition-down-enter,
.transition-down-exit,
.transition-up-enter,
.transition-up-exit {
  position: absolute;
  top: 0;
  width: 100%;
}

#entry {
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;

  &.transition-down-enter {
    opacity: 0;
    transform: translateY(100%);
  }
  &.transition-up-enter {
    opacity: 0;
    transform: translateY(50%);
  }

  &.transition-down-enter-active,
  &.transition-up-enter-active {
    opacity: 1;
    transform: translateY(0%);
  }

  &.transition-down-exit,
  &.transition-up-exit {
    opacity: 1;
    transform: translateY(0%);
  }
  &.transition-down-exit-active {
    opacity: 0;
    transform: translateY(100%);
  }
  &.transition-up-exit-active {
    opacity: 0;
    transform: translateY(50%);
  }
}
